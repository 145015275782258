import { Image } from 'primereact/image';
import { Link } from 'react-router-dom';
import { StyledHeader } from './App';

const wallCabinet = require("./assets/wallCabinet.png");
const wallCabinet2 = require("./assets/wallCabinet2.png");

export default function Design() {
  const imageWrapperStyle = {display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"};

  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <StyledHeader text={"DESIGN"}></StyledHeader>
      </div>

      <div>
        <h3 className='text-center'>
          Design d'un meuble mural
        </h3>
      </div>
  
      <div style={{marginTop: "5vh", maxWidth: '1600px', margin: "auto"}}>

        <div style={{flexDirection: "column", ...imageWrapperStyle}}>
          <Image src={wallCabinet2} width="100%" preview/>
          <Image src={wallCabinet} width="100%" preview/>
        </div>

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link to={'/scenographie'} className='custom-link'>
            <h1>
              <i className='pi pi-chevron-left' style={{fontSize: 24}}/>
              Scéno
            </h1>
          </Link>

          <Link to={'/light'} style={{right: 0}} className='custom-link'>
            <h1>
              Lampe
              <i className='pi pi-chevron-right' style={{fontSize: 24}}/>
            </h1>
          </Link>
        </div>
      </div>
    </div>
  )
};