import { Image } from 'primereact/image';
import { Link } from 'react-router-dom';
import { StyledHeader } from './App';

const light = require("./assets/light.png");
const light2 = require("./assets/light2.png");
const light3 = require("./assets/light3.png");
const light4 = require("./assets/light4.png");

export default function Light() {
  const imageWrapperStyle = {display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"};

  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <StyledHeader text={"DESIGN"}></StyledHeader>
      </div>

      <div>
        <h3 className='text-center'>
          Design d'une lampe à poser en papier
        </h3>
      </div>
  
      <div style={{marginTop: "5vh", maxWidth: '1600px', margin: "auto"}}>

        <div style={{flexDirection: "column", ...imageWrapperStyle}}>
          <Image src={light} width="100%" preview/>
          <Image src={light2} width="100%" preview/>
          <Image src={light3} width="100%" preview/>
          <Image src={light4} width="100%" preview/>
        </div>

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link to={'/design'} className='custom-link'>
            <h1>
              <i className='pi pi-chevron-left' style={{fontSize: 24}}/>
              Le meuble
            </h1>
          </Link>

          <Link to={'/contact'} style={{right: 0}} className='custom-link'>
            <h1>
              Contact
              <i className='pi pi-chevron-right' style={{fontSize: 24}}/>
            </h1>
          </Link>
        </div>
      </div>
    </div>
  )
};