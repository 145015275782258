import { Image } from 'primereact/image';
import { useNavigate } from 'react-router-dom';
import { StyledHeader } from './App';

const loft = require("./assets/loftMain.png");
const scenographie = require("./assets/scenographie.png");
const wallCabinet = require("./assets/wallCabinet.png");
const light = require("./assets/light.png");

export default function Landing() {
    const navigate = useNavigate();
    const imageWrapperStyle = {display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"};
    
    return (
    <div>
      <div style={{textAlign: 'center'}}>
        <StyledHeader text={"Mathilde Armand"}></StyledHeader>
      </div>
  
      <div className='grid gap-2 justify-content-center'>
        <div onClick={() => navigate("/loft")}
            className="col-12 lg:col-4"
            style={imageWrapperStyle}>
          <h1 style={{position: "absolute", textAlign: "center", color: "white"}}>Le Loft</h1>
          <Image src={loft} width="100%"/>
        </div>
        <div onClick={() => navigate("/scenographie")}
            className="col-12 lg:col-4"
            style={imageWrapperStyle}>
          <h1 style={{position: "absolute", textAlign: "center", color: "white"}}>Scénographie</h1>
          <Image src={scenographie} width="100%"/>
        </div>
        <div onClick={() => navigate("/design")}
            className="col-12 lg:col-4"
            style={imageWrapperStyle}>
          <h1 style={{position: "absolute", textAlign: "center", color: "white"}}>Meuble mural</h1>
          <Image src={wallCabinet} width="100%"/>
        </div>
        <div onClick={() => navigate("/light")}
            className="col-12 lg:col-4"
            style={imageWrapperStyle}>
          <h1 style={{position: "absolute", textAlign: "center", color: "white"}}>La lampe</h1>
          <Image src={light} width="100%"/>
        </div>
      </div>
    </div>
  )
};