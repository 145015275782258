import { Image } from 'primereact/image';
import { Link } from 'react-router-dom';
import { StyledHeader } from './App';

const loft1 = require("./assets/loft1.png");
const loft2 = require("./assets/loft2.png");
const loft3 = require("./assets/loft3.png");
const loft4 = require("./assets/loft4.png");
const loftMain = require("./assets/loftMain.png");


export default function Loft() {
  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <StyledHeader text={"Un loft transformé"}></StyledHeader>
      </div>

      <div>
        <h3 className='text-center'>
          Transformation d'un ancien atelier au coeur de Paris en appartement destiné à un couple
        </h3>
      </div>
  
      <div style={{marginTop: "5vh", maxWidth: '1600px', margin: "auto"}}>
        <p>Photo de l'existant: </p>
        <Image src={loft1} width="100%" preview/>

        <p>Modélisation 3D sur Sketchup: </p>
        <Image src={loft2} width="100%" preview/>
        <Image src={loft3} width="100%" preview/>
        <Image src={loftMain} width="100%" preview/>
        
        <p>Axonométrie: </p>
        <Image src={loft4} width="100%" preview/>

        <Link to={'/scenographie'} className='custom-link'>
          <h1 style={{textAlign: "right"}} >
            Scénographie <i className='pi pi-chevron-right' style={{fontSize: 24}}/>
          </h1>
        </Link>
      </div>
    </div>
  )
};