import { StyledHeader } from './App';
import { Link } from 'react-router-dom';

export default function Contact() {
    
    return (
    <div>
      <div style={{textAlign: 'center'}}>
        <StyledHeader text={"Contact"}></StyledHeader>
      </div>
  
      <div style={{textAlign: "center"}}>
        <p>Actuellement étudiante en 2ème année d'architecture intérieure
        <br/>je souhaite à l'avenir créer des espaces ou des
        <br/>objets fonctionnels, esthétiques et respecteux de l'environnement</p>
      </div>

      <div style={{textAlign: "center", marginTop: '5vh'}}>
        <p>mathilde.amd@outlook.fr</p>
      </div>

      <Link to="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <div style={{textAlign: "center", marginTop: '5vh'}}>
          <i className='pi pi-instagram' style={{fontSize: 30}}/>
        </div>
      </Link>
    </div>
  )
};