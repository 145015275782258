import React, { useEffect, useState } from 'react';
import './styles/App.css';
import { Menubar } from 'primereact/menubar';
import Theme from './Themes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './Landing';
import Loft from './Loft';
import Contact from './Contact';
import Scenographie from './Scenographie';
import ScrollToTop from './utils/ScrollToTop';
import Design from './Design';
import Light from './Light';

export const StyledHeader = (props: {text: string}) => (
  <p className="popout">
    {props.text.split(" ").map((t) => {
      if (t === "\n") return <br/>;
      return <span key={t}>{t.split("").map((l, i) => <span key={`${l}${i}`}>{l}</span>)}&nbsp;</span>
    })}
  </p>
);

const menuItems = [
  { label: 'Loft', command: () => { window.location.pathname = "/loft"; } },
  { label: 'Scénographie', command: () => { window.location.pathname = "/scenographie"; } },
  { label: 'Meuble mural', command: () => { window.location.pathname = "/design"; } },
  { label: 'La lampe', command: () => { window.location.pathname = "/light"; } },
  { label: 'Contact', icon: 'pi pi-user', command: () => { window.location.pathname = "/contact"; } },
];

function App() {
  const themes = {
    light: {icon: 'pi pi-moon', style: 'light'},
    dark: {icon: 'pi pi-sun', style: 'dark'}
  };
  const [theme, setTheme] = useState(themes.light);

  useEffect(() => {
    if (localStorage.getItem('theme')) {
      return setTheme(themes.dark.style === localStorage.getItem('theme') ? themes.dark : themes.light);
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme(themes.dark);
      localStorage.setItem('theme', themes.dark.style);
    }
  // eslint-disable-next-line
  }, []);

  const handleThemeChange = () => {
    const newTheme = theme.style === themes.dark.style ? themes.light : themes.dark;
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme.style);
  };

  return (
    <div>
    <BrowserRouter>
      <ScrollToTop/>
      <Theme theme={theme.style} />
      <Menubar
        style={{position: "fixed", zIndex: 100, width: '100%', top: 0}}
        model={menuItems}
        start={
          <i className="pi pi-home" onClick={() => window.location.pathname = "/"} style={{cursor: "pointer"}}></i>
        }
        end={
          <i className={theme.icon} onClick={handleThemeChange}></i>
        }
      />

      <div style={{margin: "5vh auto", width: "90%", paddingTop: 40}}>
        <Routes>
          <Route path="/" element={<Landing/>} />
          <Route path="/loft" element={<Loft/>} />
          <Route path="/scenographie" element={<Scenographie/>} />
          <Route path="/design" element={<Design/>} />
          <Route path="/light" element={<Light/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
      </div>
    </BrowserRouter>
    </div>
  );
}

export default App;
