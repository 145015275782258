import { Image } from 'primereact/image';
import { Link } from 'react-router-dom';
import { StyledHeader } from './App';

const scenographie = require("./assets/scenographie.png");
const scenographie2 = require("./assets/scenographie2.png");
const scenographie3 = require("./assets/scenographie3.png");
const scenographie4 = require("./assets/scenographie4.png");


export default function Scenographie() {
  const imageWrapperStyle = {display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"};

  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <StyledHeader text={"Scéno"}></StyledHeader>
      </div>

      <div>
        <h3 className='text-center'>
          Création d'une scénographie pour l'artiste <b>Giacometti</b> au sein de l'Orangerie du Sénat
        </h3>
      </div>
  
      <div style={{marginTop: "5vh", maxWidth: '1600px', margin: "auto"}}>

        <div style={{flexDirection: "column", ...imageWrapperStyle}}>
          <Image src={scenographie} width="100%" preview/>
          <Image src={scenographie2} width="100%" preview/>
          <Image src={scenographie3} width="100%" preview/>
          <Image src={scenographie4} width="100%" preview/>
        </div>

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link to={'/loft'} className='custom-link'>
            <h1>
              <i className='pi pi-chevron-left' style={{fontSize: 24}}/>
              Loft
            </h1>
          </Link>

          <Link to={'/design'} style={{right: 0}} className='custom-link'>
            <h1>
              Design
              <i className='pi pi-chevron-right' style={{fontSize: 24}}/>
            </h1>
          </Link>
        </div>
      </div>
    </div>
  )
};